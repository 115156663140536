<div class="heading-bar">
  <p class="center mat-h1">rzakariudakis@gmail.com</p>
</div>
<div class="center"><a class="mat-icon-button" href="mailto:rzakariudakis@gmail.com" target="_blank"><mat-icon>email</mat-icon></a></div>
<table class="center">
  <tr>
    <td>
      <div class="mat-h3">you can also find me on</div>
    </td>
  </tr>
  <tr>
    <td>
      <a href="https://www.linkedin.com/in/ryan-zakariudakis" target="_blank"><img class="linkedin-logo"
           src="../../assets/linkedin-logo.png"
              alt="https://www.linkedin.com/in/ryan-zakariudakis"></a>
    </td>
  </tr>
</table>
<table class="center">
  <tr>
    <td>
      <div class="mat-h3">and you can read my thoughts on</div>
    </td>
  </tr>
  <tr>
    <td>
      <a href="https://medium.com/@rzakariudakis" target="_blank"><img class="medium-logo" src="../../assets/medium-logo.png" alt="https://medium.com/@rzakariudakis"></a>
    </td>
  </tr>
</table>
