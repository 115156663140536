<div class="heading-bar center mat-h1">I love programming. I love technology.</div>
<mat-card class="center mat-elevation-z3">
  <mat-card-content>
    <div class="content">
      <p class="center">
        <b>I always knew that Programming was for me.</b>
      </p>
      <p>
        I began my career as a software tester which was a great stepping stone into software development, it showed me all
        the aspects of the development process. This gives me a different perspective on system design & implementation.
      </p>
      <p>
        I am passionate about coding and I am always keen to tackle the challenges thrown my way especially if I can flex my
        polyglot muscles.
      </p>
      <p class="center"><mat-icon>keyboard</mat-icon></p>
      <p>I have a strong work ethic while always aiming to deliver the highest quality work. </p>
      <p>I thrive in a team environment where my team mates and I can push each other to ensure we deliver to the best of
        our abilities.</p>
      <p>I am comfortable leading projects and making decisions. I pride myself on my ability to remain pragmatic and calm
        under pressure.</p>
      <p class="center"><mat-icon>bug_report</mat-icon></p>
      <p>I like to challenge myself by always keeping at least one coding project on the go in my spare time to let me
        experiment with new technologies and ideas that I might have.</p>
      <p>I enjoy cooking, reading (Biographies, Science Fiction novels and Fantasy novels) and some online gaming to unwind at the end of it all.</p>
    </div>
    <br>
    <div><p class="center mat-h3">Last but not least, I really really love coffee</p></div>
    <div class="center">
      <mat-icon>keyboard_arrow_left</mat-icon>
      <mat-icon>coffee</mat-icon>
      <mat-icon>keyboard_arrow_right</mat-icon>
      <mat-icon>keyboard_arrow_left</mat-icon>
      <mat-icon>coffee</mat-icon>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
  </mat-card-content>
</mat-card>
