<div>
  <h1 class="name" style="text-align: center">ryan zakariudakis</h1>
</div>
<div class="toolbar center">
  <button mat-button routerLink="/home" routerLinkActive="active-top-level-link">home</button>
  <button routerLink="/about" routerLinkActive="active-top-level-link" mat-button aria-label="about me">about me</button>
  <button routerLink="/my-work" routerLinkActive="active-top-level-link" mat-button aria-label="my work">my work</button>
  <button routerLink="/contact" routerLinkActive="active-top-level-link" mat-button aria-label="contact me">contact me</button>
  <button routerLink="/zorbees-kitchen" routerLinkActive="active-zorbees-kitchen" mat-button aria-label="zorbees kitchen">Zorbee's kitchen</button>
</div>
<router-outlet></router-outlet>
