import { Component, OnInit } from '@angular/core';
import {BreakPointHelper} from "../layout/break-point-helper";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";

@Component({
  selector: 'app-my-work',
  templateUrl: './my-work.component.html',
  styleUrls: ['./my-work.component.scss']
})
export class MyWorkComponent implements OnInit {

  constructor(public breakpointObserver: BreakpointObserver) {}
  panelOpenState = false;
  cardImgMaxWidth = "350px";
  ngOnInit(): void {
    this.breakpointObserver
      .observe(BreakPointHelper.AllBreakpoints)
      .subscribe((state: BreakpointState) => {
        if (BreakPointHelper.isSmallDevicePortrait(state.breakpoints)) {
          this.cardImgMaxWidth = "150px"
        } else if (BreakPointHelper.isSmallDeviceLandscape(state.breakpoints)) {
          this.cardImgMaxWidth = "200px"
        } else if (BreakPointHelper.isMediumDevicePortrait(state.breakpoints)) {
          this.cardImgMaxWidth = "200px"
        } else if (BreakPointHelper.isMediumDeviceLandscape(state.breakpoints)) {
          this.cardImgMaxWidth = "250px"
        } else if (BreakPointHelper.isLargeDevicePortrait(state.breakpoints)) {
          this.cardImgMaxWidth = "250px"
        } else if (BreakPointHelper.isLargeDeviceLandscape(state.breakpoints)) {
          this.cardImgMaxWidth = "300px"
        } else {
          console.log("didnt detect breakpoints")
          console.log(state)
        }
      });
  }

}
