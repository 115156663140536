<div class="content">
  <p class="center"><img class="initials" src="assets/zorbees-kitchen-initials-small.png" alt="Zorbee's Kitchen Initials"/></p>
  <p>I've built a recipe app, but a recipe app based on ingredients you actually have an enjoy cooking leaving out all
    the icky ones you don't like.</p>
  <p class="center"><img class="image-row" src="assets/broccoli_row.png" alt="Broccoli head to head"/></p>
  <p>I've designed and built the whole system from front to back myself. I've taken a bet on Flutter and am enjoying a
    high level of success in using it to write code once and produce the same experience on multiple platforms.</p>
  <p class="center"><img class="image-row" src="assets/carrot_row.png" alt="Carrots head to head"/></p>
  <p>Design apps and front ends is not something that comes naturally to me but the journey i've been on when building
    Zorbee's Kitchen has been invaluable.</p>
</div>
<div class="center">
  <p><a href="https://zorbeeskitchen.com" target="_blank"><img class="zorbees-kitchen-logo"
                                                               src="assets/zorbees-kitchen-logo.png" alt="https://zorbeeskitchen.com"></a></p>
</div>
<div class="center">
  <div>
    <a class="apple-badge" href="https://apps.apple.com/us/app/zorbees-kitchen/id1521335655?itsct=apps_box&amp;itscg=30200" target="_blank"><img class="apple-store-logo" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1600732800&h=037816b7f1805597dd8f35ef6390eea7" alt="Download on the App Store"></a>
    <br>
    <a href='https://play.google.com/store/apps/details?id=com.zorbitt.iaK7DEl0f4Jm&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img class="play-store-logo" alt='Get it on Google Play' src='assets/google-play-badge.png'/></a>
  </div>
</div>
