import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {AboutComponent} from "./about/about.component";
import {MyWorkComponent} from "./my-work/my-work.component";
import {ContactMeComponent} from "./contact-me/contact-me.component";
import {ZorbeesKitchenComponent} from "./zorbees-kitchen/zorbees-kitchen.component";

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'my-work', component: MyWorkComponent},
  {path: 'contact', component: ContactMeComponent},
  {path: 'zorbees-kitchen', component: ZorbeesKitchenComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
