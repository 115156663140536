import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {BreakPointHelper} from "../layout/break-point-helper";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public breakpointObserver: BreakpointObserver) {
  }

  gridColumns = 4;

  ngOnInit(): void {

    this.breakpointObserver
      .observe(BreakPointHelper.AllBreakpoints)
      .subscribe((state: BreakpointState) => {
        if (BreakPointHelper.isSmallDevicePortrait(state.breakpoints)) {
          this.gridColumns = 1
        } else if (BreakPointHelper.isSmallDeviceLandscape(state.breakpoints)) {
          this.gridColumns = 2
        } else if (BreakPointHelper.isMediumDevicePortrait(state.breakpoints)) {
          this.gridColumns = 2
        } else if (BreakPointHelper.isMediumDeviceLandscape(state.breakpoints)) {
          this.gridColumns = 3
        } else if (BreakPointHelper.isLargeDevicePortrait(state.breakpoints)) {
          this.gridColumns = 3
        } else if (BreakPointHelper.isLargeDeviceLandscape(state.breakpoints)) {
          this.gridColumns = 4
        } else {
          console.log("didnt detect breakpoints")
          console.log(state)
        }
      });
  }



}
