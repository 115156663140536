<div class="center" [hidden]="true">
  <img class="profile-pic" src="assets/me.jpg" alt="Picture of Me">
  <!--  TODO add decent picture-->
</div>
<div class="heading-bar">
  <p class="center mat-h1">Creating and making is what I do, this is my toolbox</p>
</div>
<p class="center"><mat-icon>keyboard</mat-icon><mat-icon>construction</mat-icon></p>
<mat-grid-list [cols]="gridColumns" rowHeight="120px" class="center">
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="../../assets/golang-logo.png" alt="Golang logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="../../assets/kotlin-logo.png" alt="Kotlin logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="../../assets/java-logo.png" alt="Java logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="assets/flutter-logo.png" alt="Flutter logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="assets/dart-logo.png" alt="Dart logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="assets/typescript-logo.png" alt="Typescript logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="assets/docker-logo.jpeg" alt="Neo4j logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="assets/spring-boot-logo.png" alt="Spring Boot logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="assets/neo4j-logo.png" alt="Neo4j logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="assets/mysql-logo.png" alt="Mysql logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="assets/gcloud-logo.jpeg" alt="Google Cloud Platform logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="../../assets/aws-logo.png" alt="AWS logo"></mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card><img class="tech-logo" src="assets/jira-logo.png" alt="JIRA logo"></mat-card>
  </mat-grid-tile>
</mat-grid-list>
