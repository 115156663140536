import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zorbees-kitchen',
  templateUrl: './zorbees-kitchen.component.html',
  styleUrls: ['./zorbees-kitchen.component.scss']
})
export class ZorbeesKitchenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
