<div class="heading-bar">
  <p class="center mat-h1">Hand made...technically anyway</p>
</div>
<div class="my-work">
  <p>I aim to design reliable and responsive services for any project I work on.</p>
  <p>I am passionate about building services & systems, especially while learning with new technologies and techniques.</p>
</div>
<mat-card class="mat-elevation-z3">
  <mat-card-header>
    <mat-card-title>Zorbee's Kitchen</mat-card-title>
    <mat-card-subtitle>Released in <i>2020</i></mat-card-subtitle>
  </mat-card-header>
  <img class="zorbees-kitchen-logo" [style.max-width]="cardImgMaxWidth" src="assets/zorbees-kitchen-logo.png" alt="Zorbee's Kitchen logo">
  <mat-card-content>
    <p>This is a work in progress but more about that <a routerLink="/zorbees-kitchen">here</a></p>
    <span>The main technologies utilized were <span class="technology-highlight">Kotlin</span>, <span
      class="technology-highlight">Google Cloud Platform</span>, <span
      class="technology-highlight">Docker</span>, <span
      class="technology-highlight">Flutter</span>, <span
      class="technology-highlight">Firebase</span>, <span
      class="technology-highlight">Springboot</span>, <span
      class="technology-highlight">MySQL</span> and <span class="technology-highlight">Neo4j</span></span>
  </mat-card-content>
</mat-card>
<br>
<mat-card class="mat-elevation-z3">
  <mat-card-header>
    <mat-card-title>Your Kitchen Pal</mat-card-title>
    <mat-card-subtitle>Released in <i>2019</i></mat-card-subtitle>
  </mat-card-header>
  <img class="kitchen-pal-logo" [style.max-width]="cardImgMaxWidth" src="assets/your-kitchen-pal-logo-min.png" alt="Your Kitchen Pal logo">
  <mat-card-content>
    <p>My first version of a mobile app and website to help inspire you when trying to decide what to cook, i've since
      renamed and rebranded it to Zorbee's Kitchen. More about that <a routerLink="/zorbees-kitchen">here</a></p>
    <span>The main technologies utilized were <span class="technology-highlight">Kotlin</span>, <span
      class="technology-highlight">Google Cloud Platform</span>, <span
      class="technology-highlight">Docker</span>, <span
      class="technology-highlight">Flutter</span>, <span
      class="technology-highlight">Firebase</span>, <span
      class="technology-highlight">Springboot</span>, <span
      class="technology-highlight">MySQL</span> and <span class="technology-highlight">Neo4j</span></span>
  </mat-card-content>
</mat-card>
<br>
<mat-card class="mat-elevation-z3">
  <mat-card-header>
    <mat-card-title>API Monitor</mat-card-title>
    <mat-card-subtitle>Completed in <i>2015</i></mat-card-subtitle>
  </mat-card-header>
  <img class="card-image" [style.max-width]="cardImgMaxWidth" src="assets/magnifying-glass.jpeg" alt="Magnifying glass">
  <mat-card-content>
    <div>
        <p><a href="https://gitlab.com/api-performance-monitoring/performance-monitor-api/blob/master/README.md"
                 title="https://gitlab.com/api-performance-monitoring/performance-monitor-api/blob/master/README.md"
                 target="_blank">gitlab repository</a></p>
      <p>I created a custom telemetry service which you hook into your own API to monitor requests and performance. The idea is to host the service alongside your own service in order to pass along all requests for monitoring. This allows you to monitor your services for bottlenecks and problems. Once the data is collected you can also query the data to pull statistics and reporting.</p>
      <p>The main reason for creating this project was a lack of easily accessible and open source or free services to provide the same functionality</p>
      <p>The main technologies utilized were <span class="technology-highlight">Java</span>, <span
        class="technology-highlight">REST</span> and the <span class="technology-highlight">Wildfly</span> application server</p>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card class="mat-elevation-z3">
  <mat-card-header>
    <mat-card-title>Flights Price Scraper - Prototype</mat-card-title>
    <mat-card-subtitle>Completed in <i>2017</i></mat-card-subtitle>
  </mat-card-header>
  <img class="card-image" [style.max-width]="cardImgMaxWidth" src="assets/aeroplane.jpeg" alt="Aeroplane">
  <mat-card-content>
    <div>
       <p><a href="https://gitlab.com/flights-analyser/prototype-flights-price-scraper/blob/master/README.md"
                title="https://gitlab.com/flights-analyser/prototype-flights-price-scraper/blob/master/README.md"
                target="_blank">gitlab repository</a></p>
      <p>I created a prototype application which scraped prices from a flight search website. The aim of the prototype was to do the same actions I normally did using the website in an automated way.</p>
      <p>The point was to reduce my effort in finding the cheapest flights when planning a trip.</p>
      <p>The main technologies utilized were <span class="technology-highlight">Selenium (Java)</span>, <span
        class="technology-highlight">NodeJS</span>, <span class="technology-highlight">MongoDB</span>, <span
        class="technology-highlight">MySQL</span> </p>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card class="mat-elevation-z3">
  <mat-card-header>
    <mat-card-title>Cheap Flights Analyser</mat-card-title>
    <mat-card-subtitle>in progress</mat-card-subtitle>
  </mat-card-header>
  <img class="card-image" [style.max-width]="cardImgMaxWidth" src="assets/comparison-scales.png" alt="Scales">
  <mat-card-content>
    <div>
      <p>I am in the process of turning my 'Flights Price Scraper' prototype into a working application.</p>
      <p>This will be a flights search and analysis application utilizing Skyscanner’s free Flights API as the data provider.</p>
      <p>The main technologies utilized are <span class="technology-highlight">Java</span>, <span
        class="technology-highlight">Kotlin</span>, <span class="technology-highlight">JavaSpark</span>, <span
        class="technology-highlight">VueJS</span>, <span class="technology-highlight">MySQL</span></p>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card class="mat-elevation-z3">
  <mat-card-header>
    <mat-card-title>Maven Dependency Tree Visualizer</mat-card-title>
    <mat-card-subtitle>in progress</mat-card-subtitle>
  </mat-card-header>
  <img class="card-image" [style.max-width]="cardImgMaxWidth" src="assets/maven-logo.png" alt="Maven logo">
  <mat-card-content>
    <div>
      <p>I am creating an application to visually represent the dependency tree of a Maven project.</p>
      <p>This is done utilizing the Maven CLI or the output of a <span class="code-block">mvn dependency:tree -DoutputType=dot -Dmaven.multiModuleProjectDirectory=$M2_HOME</span> command.</p>
      <p>The main technologies utilized are <span class="technology-highlight">Java</span>, <span
        class="technology-highlight">VueJS</span>, <span class="technology-highlight">TypeScript</span></p>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card class="mat-elevation-z3">
  <mat-card-header>
    <mat-card-title>Bathroom Occupied Display - Arduino Project</mat-card-title>
    <mat-card-subtitle>Completed in <i>2017</i></mat-card-subtitle>
  </mat-card-header>
  <img class="card-image" [style.max-width]="cardImgMaxWidth" src="assets/bathroom-display.jpeg" alt="Bathroom occupied display">
  <mat-card-content>
    <div>
      <p><a href="https://gitlab.com/arduino-sensors/bathroom-occupied-rotary-encoder-oled/blob/master/README.md"
               title="https://gitlab.com/arduino-sensors/bathroom-occupied-rotary-encoder-oled/blob/master/README.md"
               target="_blank">gitlab repository</a></p>
      <p>I learnt how to design and soldier PCB circuit boards so that I could create my own customized bathroom occupied status display unit.</p>
      <p>The idea came to me because we had visitors coming around and I had no lock on the door and didn't want to go with the boring option of adding a lock.</p>
      <p>So I ended up creating my own circuit board and case using Lego with and Arduino Nano as the microcontroller with a 64x128 OLED mini display and a rotary encoder to control the setting.</p>
      <p>It is battery powered and lasts about 8 and a half days from fully charged batteries if left on all the time.</p>
      <p>The main technologies utilized were <span class="technology-highlight">Arduino (mixture of C & C++)</span> and <span
        class="technology-highlight">electronics</span></p>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card class="mat-elevation-z3">
  <mat-card-header>
    <mat-card-title>Command line SQL execution Tool</mat-card-title>
    <mat-card-subtitle>Completed in <i>2014</i></mat-card-subtitle>
  </mat-card-header>
  <img class="card-image" [style.max-width]="cardImgMaxWidth" src="assets/mysql-logo.png" alt="MySQL">
  <mat-card-content>
    <div>
      <p><a href="https://gitlab.com/ryan.zakariudakis/database-script-executor/blob/master/README.md"
               title="https://gitlab.com/ryan.zakariudakis/database-script-executor/blob/master/README.md"
               target="_blank">gitlab repository</a></p>
      <p>Command line tool for scheduling multiple SQL queries to execute (in parallel if desired).</p>
      <p>The reason for this project was to have a way to execute long running MySQL transactions on a very very large database that required maintenance.</p>
      <p>Using this I was able to execute many transactions after hours with the ability to see results the next day. This saved a huge amount of manual work and lowered the risk of human error.</p>
      <p>The main technologies utilized were <span class="technology-highlight">Java</span> and <span
        class="technology-highlight">SQL</span></p>
    </div>
  </mat-card-content>
</mat-card>
<br>
<br>
