import {Breakpoints} from "@angular/cdk/layout";

export class BreakPointHelper {
  static AllBreakpoints = [
    Breakpoints.HandsetPortrait,
    Breakpoints.HandsetLandscape,
    Breakpoints.TabletPortrait,
    Breakpoints.TabletLandscape,
    Breakpoints.WebPortrait,
    Breakpoints.WebLandscape,
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
  ];

  public static isLandscape(breakpoints: { [p: string]: boolean }): boolean {
    if (breakpoints[Breakpoints.HandsetLandscape]) return true
    if (breakpoints[Breakpoints.TabletLandscape]) return true
    return breakpoints[Breakpoints.WebLandscape]
  }

  public static isPortrait(breakpoints: { [p: string]: boolean }): boolean {
    if (breakpoints[Breakpoints.HandsetPortrait]) return true
    if (breakpoints[Breakpoints.TabletPortrait]) return true
    return breakpoints[Breakpoints.WebPortrait]
  }

  public static isSmallDevicePortrait(breakpoints: { [p: string]: boolean }): boolean {
    if (BreakPointHelper.isPortrait(breakpoints)) {
      if (breakpoints[Breakpoints.XSmall]) return true
      if (breakpoints[Breakpoints.Small]) return true
    }
    return false;
  }

  public static isSmallDeviceLandscape(breakpoints: { [p: string]: boolean }): boolean {
    if (BreakPointHelper.isLandscape(breakpoints)) {
      if (breakpoints[Breakpoints.XSmall]) return true
      if (breakpoints[Breakpoints.Small]) return true
    }
    return false;
  }

  public static isMediumDevicePortrait(breakpoints: { [p: string]: boolean }): boolean {
    if (BreakPointHelper.isPortrait(breakpoints)) {
      if (breakpoints[Breakpoints.Medium]) return true
    }
    return false;
  }

  public static isMediumDeviceLandscape(breakpoints: { [p: string]: boolean }): boolean {
    if (BreakPointHelper.isLandscape(breakpoints)) {
      if (breakpoints[Breakpoints.Medium]) return true
    }
    return false;
  }

  public static isLargeDevicePortrait(breakpoints: { [p: string]: boolean }): boolean {
    if (BreakPointHelper.isPortrait(breakpoints)) {
      if (breakpoints[Breakpoints.Large]) return true
      if (breakpoints[Breakpoints.XLarge]) return true
    }
    return false;
  }

  public static isLargeDeviceLandscape(breakpoints: { [p: string]: boolean }): boolean {
    if (BreakPointHelper.isLandscape(breakpoints)) {
      if (breakpoints[Breakpoints.Large]) return true
      if (breakpoints[Breakpoints.XLarge]) return true
    }
    return false;
  }
}
